import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UseBrowser } from './use_browser/use_browser';

const LoadApp = ()=>{
  const root = ReactDOM.createRoot(document.getElementById('root'));
  if(is_weixn()){
    root.render(<UseBrowser/>);
  }else{
    root.render(<App />);
  }
  
  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();  
}

function is_weixn(){
  var ua = navigator.userAgent.toLowerCase();
  if(ua.indexOf("micromessenger") >= 0) {
      return true;
  } else {
      return false;
  }
}

// eslint-disable-next-line no-undef
loaded().then(()=>{LoadApp()})
