import { Button, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { LogoEnIcon } from "../assets/icons/logo_en_icon";
import { useEffect } from "react";
import axios from "axios";

export const StuDownload = () => {

    const navigate = useNavigate();



    let params = new URLSearchParams(window.location.href.split("?")[1])

    let code = "";
    let codeName = ""

    if (params.has("teacherinviteCode")) {
        codeName = "管理团队码";
        code = params.get("teacherinviteCode");
    } else if (params.has("assistinviteCode")) {
        codeName = "测试团队码";
        code = params.get("assistinviteCode");
    } else if (params.has("inviteCode")) {
        codeName = "学生邀请码";
        code = params.get("inviteCode");
    }

    useEffect(() => {
    }, [])

    const copyText = async (val) => {
        if (navigator.clipboard && navigator.permissions) {
            await navigator.clipboard.writeText(val)
        } else {
            const textArea = document.createElement('textArea')
            textArea.value = val
            textArea.style.width = 0
            textArea.style.position = 'fixed'
            textArea.style.left = '-999px'
            textArea.style.top = '10px'
            textArea.setAttribute('readonly', 'readonly')
            document.body.appendChild(textArea)
            textArea.select()
            document.execCommand('copy')
            document.body.removeChild(textArea)
        }
        Toast.show("已复制");
    }

    const invokeApp = () => {
        document.getElementById("applink").contentWindow.location.href = "ticequan://fitnessatlas.cn/" + window.location.search;
        if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
            window.open("ticequan://fitnessatlas.cn/" + window.location.search)
        }
    }

    const InviteCodeWidget = () => {
        return (
            <div className='HomeCardWithTitle'>
                <div style={{
                    "padding": "15px 25px",
                    "display": "flex",
                    flexDirection: "column",
                    "alignItems": "center",
                    justifyContent: "center"
                }}>
                    <div style={{
                        "width": "100%",
                        "display": "flex",
                        flexDirection: "row",
                        "alignItems": "center",
                        justifyContent: "center"
                    }}>
                        <div
                            style={{
                                "fontSize": "1.5em",
                                "fontWeight": "bolder",
                            }}
                        >{code}</div>
                        <div style={{ "flex": "1" }}></div>
                        <Button color="primary" fill='outline' onClick={() => {
                            copyText(code);
                        }}
                        >复制</Button>
                    </div>
                </div>
            </div>
        )
    }

    const DownloadWidget = () => {
        return (
            <>
                <div className='HomeCardWithTitle'>
                    <div style={{
                        "padding": "15px 25px",
                        "display": "flex",
                        flexDirection: "row",
                        "alignItems": "center"
                    }}>
                        <div
                            style={{
                                "fontSize": "2.0em",
                                "fontWeight": "bolder"
                            }}
                        >iOS</div>
                        <div style={{ "flex": "1" }}></div>
                        <Button color="primary" fill='outline' onClick={() => {
                            window.open("https://apps.apple.com/cn/app/%E4%BD%93%E6%B5%8B%E5%9C%88/id1641436881")
                        }}>下载</Button>
                    </div>
                </div>

                <div className='HomeCardWithTitle'>
                    <div style={{
                        "padding": "15px 25px",
                        "display": "flex",
                        flexDirection: "row",
                        "alignItems": "center"
                    }}>
                        <div
                            style={{
                                "fontSize": "2.0em",
                                "fontWeight": "bolder"
                            }}
                        >Android</div>
                        <div style={{ "flex": "1" }}></div>
                        <Button color="primary" fill='outline' onClick={() => {
                            window.open("https://static.fitnessatlas.cn/ticequan.apk")
                        }}>下载</Button>
                    </div>
                </div>
            </>
        )
    }

    return (
        <div style={{
            "display": "flex",
            "flexDirection": "column",
            "height": "100vh"
        }}>
            <div style={
                {
                    "display": "flex",
                    "padding": "30px 35px 0px 35px"
                }
            }>
                <div className="fitnessatlasIcon">
                    <LogoEnIcon />
                </div>
                <div style={{ "flex": "1" }}></div>
                <div className="HomeTitle">
                    <div style={{
                        "fontWeight": "bolder",
                        "fontSize": "1em",
                        "color": "#707070"
                    }}>体质健康测试</div>
                    <div style={{
                        "fontWeight": "bolder",
                        "fontSize": "1.4em"
                    }}>下载指引</div>
                </div>
            </div>
            <div style={{
                fontWeight: "bolder",
                fontSize: "1em",
                color: "#707070",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px"
            }}>
                使用下方{" " + codeName + " "}加入学校
            </div>
            {code !== "" ? <InviteCodeWidget /> : null}
            <DownloadWidget />

            {code !== "" ?
                (<div className='HomeCardWithTitle'>
                    <Button color="primary" size='large' onClick={() => {
                        invokeApp();
                    }}
                    >打开App，并自动加入学校</Button>
                </div>) : null
            }

            <iframe src="" id="applink" title="applink" style={{
                visibility: "collapse"
            }}></iframe>
        </div>
    )
}