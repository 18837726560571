import { Image } from "antd-mobile"

export const LogoEnIcon = () => {
    return (
        <Image src="icon_logo.png"></Image>
        // <image  width="100%" height="100%" viewBox="0 0 733 220"  src="icon_logo.png"></image>
        // <svg width="100%" height="100%" viewBox="0 0 733 220" version="1.1" xmlns="http://www.w3.org/2000/svg">
        //     <title>logo_en_icon</title>
        //     <defs>
        //         <linearGradient x1="61.6884568%" y1="46.6060891%" x2="38.3115432%" y2="53.3939109%" id="linearGradient-1">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="57.0973002%" y1="-5.43298122%" x2="25.4038067%" y2="88.0869132%" id="linearGradient-2">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="61.2586298%" y1="44.8388028%" x2="38.8121791%" y2="55.1611972%" id="linearGradient-3">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="52.2968343%" y1="15.8806133%" x2="47.6977143%" y2="84.1193867%" id="linearGradient-4">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="61.6214102%" y1="46.6098351%" x2="38.2668455%" y2="53.3939109%" id="linearGradient-5">
        //             <stop stop-color="#2684FF" offset="0%"></stop>
        //             <stop stop-color="#0558D2" offset="50%"></stop>
        //             <stop stop-color="#2684FF" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="55.2890466%" y1="19.6300835%" x2="43.6835994%" y2="85.2203877%" id="linearGradient-6">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="56.9006861%" y1="5.2928159%" x2="26.7255224%" y2="88.8954629%" id="linearGradient-7">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="52.7849148%" y1="16.6956438%" x2="47.2150852%" y2="83.3043562%" id="linearGradient-8">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="61.6623347%" y1="46.5710915%" x2="38.3150638%" y2="53.4289085%" id="linearGradient-9">
        //             <stop stop-color="#2684FF" offset="34%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="42.0921482%" y1="14.0739018%" x2="74.174482%" y2="92.5415547%" id="linearGradient-10">
        //             <stop stop-color="#2684FF" offset="42%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="67.9963208%" y1="5.41698204%" x2="33.6559392%" y2="97.0079843%" id="linearGradient-11">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="67.9963208%" y1="5.41698204%" x2="33.6457127%" y2="97.0079843%" id="linearGradient-12">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="60.8476851%" y1="43.6817907%" x2="39.1075822%" y2="56.3182093%" id="linearGradient-13">
        //             <stop stop-color="#2684FF" offset="39%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="29.8740594%" y1="20.2268544%" x2="64.1144955%" y2="101.902161%" id="linearGradient-14">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="51.8448427%" y1="14.4777167%" x2="48.3426186%" y2="85.7985411%" id="linearGradient-15">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="73.4026052%" y1="6.63909252%" x2="44.5361448%" y2="92.8957346%" id="linearGradient-16">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="26.5930492%" y1="93.3609075%" x2="55.4600761%" y2="7.10426537%" id="linearGradient-17">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="61.6884568%" y1="46.6098351%" x2="38.3115432%" y2="53.3939109%" id="linearGradient-18">
        //             <stop stop-color="#2684FF" offset="0%"></stop>
        //             <stop stop-color="#0558D2" offset="50%"></stop>
        //             <stop stop-color="#2684FF" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="55.2847639%" y1="19.6484607%" x2="43.6869808%" y2="85.2226235%" id="linearGradient-19">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="60.8924178%" y1="43.6817907%" x2="39.1523149%" y2="56.3182093%" id="linearGradient-20">
        //             <stop stop-color="#2684FF" offset="39%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //         <linearGradient x1="29.8740594%" y1="20.2268544%" x2="64.1256125%" y2="101.902161%" id="linearGradient-21">
        //             <stop stop-color="#2684FF" offset="65%"></stop>
        //             <stop stop-color="#0558D2" offset="100%"></stop>
        //         </linearGradient>
        //     </defs>
        //     <g id="页面-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        //         <g id="logo_en_icon" transform="translate(0.004990, 0.002239)" fill-rule="nonzero">
        //             <path d="M168.56501,115.247761 L206.06501,115.247761 C210.89501,115.247761 210.89501,107.747761 206.06501,107.747761 L168.56501,107.747761 C163.73501,107.747761 163.73501,115.247761 168.56501,115.247761 Z" id="路径" fill="url(#linearGradient-1)"></path>
        //             <path d="M167.81501,166.997761 L167.81501,114.567761 C167.81501,100.347761 164.81501,78.5677606 181.58501,72.0177606 C193.58501,67.3477606 208.43501,67.8677606 221.06501,68.7477606 C225.88501,69.0777606 225.86501,61.5777606 221.06501,61.2477606 C209.06501,60.4177606 196.37501,60.5677606 184.63501,63.3077606 C170.92501,66.5077606 162.87501,76.8877606 160.73501,90.5377606 C159.90501,95.7977606 160.32501,101.237761 160.32501,106.537761 L160.32501,166.997761 C160.32501,171.827761 167.82501,171.827761 167.82501,166.997761 L167.81501,166.997761 Z" id="路径" fill="url(#linearGradient-2)"></path>
        //             <path d="M225.29501,96.7477606 L246.29501,96.7477606 C251.12501,96.7477606 251.12501,89.2477606 246.29501,89.2477606 L225.29501,89.2477606 C220.46501,89.2477606 220.46501,96.7477606 225.29501,96.7477606 Z" id="路径" fill="url(#linearGradient-3)"></path>
        //             <path d="M232.06501,109.997761 L232.06501,166.997761 C232.06501,171.827761 239.56501,171.827761 239.56501,166.997761 L239.56501,109.997761 C239.56501,105.167761 232.06501,105.167761 232.06501,109.997761 Z" id="路径" fill="url(#linearGradient-4)"></path>
        //             <path d="M261.54501,114.327761 L299.06501,114.327761 C303.89501,114.327761 303.89501,106.827761 299.06501,106.827761 L261.56501,106.827761 C256.73501,106.827761 256.73501,114.327761 261.56501,114.327761 L261.54501,114.327761 Z" id="路径" fill="url(#linearGradient-5)"></path>
        //             <path d="M275.29501,88.0777606 L275.29501,134.137761 C275.29501,140.077761 275.22501,146.007761 275.29501,151.947761 C275.43501,164.867761 286.29501,173.587761 299.04501,169.697761 C303.64501,168.287761 301.68501,161.047761 297.04501,162.467761 C289.44501,164.797761 282.92501,160.467761 282.77501,152.467761 C282.77501,151.287761 282.77501,150.087761 282.77501,148.897761 L282.77501,88.0977606 C282.77501,83.2677606 275.27501,83.2677606 275.27501,88.0977606 L275.29501,88.0777606 Z" id="路径" fill="url(#linearGradient-6)"></path>
        //             <path d="M318.06501,166.997761 L318.06501,132.857761 C318.06501,128.857761 317.22501,123.617761 318.65501,119.707761 C323.12501,107.527761 342.49501,110.157761 344.65501,122.357761 C345.51501,127.097761 352.73501,125.087761 351.88501,120.357761 C349.52501,107.287761 334.47501,101.147761 322.77501,105.937761 C314.54501,109.307761 310.51501,116.587761 310.51501,125.147761 L310.51501,166.997761 C310.51501,171.827761 318.01501,171.827761 318.01501,166.997761 L318.06501,166.997761 Z" id="路径" fill="url(#linearGradient-7)"></path>
        //             <path d="M344.51501,122.477761 L344.51501,166.997761 C344.51501,171.827761 352.01501,171.827761 352.01501,166.997761 L352.01501,122.477761 C352.01501,117.647761 344.51501,117.647761 344.51501,122.477761 Z" id="路径" fill="url(#linearGradient-8)"></path>
        //             <path d="M363.49501,143.167761 L400.49501,143.167761 C405.32501,143.167761 405.32501,135.667761 400.49501,135.667761 L363.49501,135.667761 C358.66501,135.667761 358.66501,143.167761 363.49501,143.167761 Z" id="路径" fill="url(#linearGradient-9)"></path>
        //             <path d="M400.49501,108.347761 C382.18501,106.007761 360.20501,118.717761 359.75501,138.897761 C359.30501,159.077761 381.44501,172.297761 399.65501,170.477761 C404.41501,169.997761 404.46501,162.477761 399.65501,162.977761 C385.65501,164.377761 366.77501,155.277761 367.25501,138.897761 C367.73501,122.517761 386.36501,113.997761 400.49501,115.847761 C405.26501,116.457761 405.22501,108.947761 400.49501,108.347761 Z" id="路径" fill="url(#linearGradient-10)"></path>
        //             <path d="M448.71501,107.247761 C436.86501,107.387761 421.13501,110.247761 416.25501,122.777761 C412.09501,133.477761 419.25501,139.337761 428.63501,142.427761 C433.29501,143.957761 438.25501,144.757761 442.79501,146.627761 C445.79501,147.847761 446.55501,148.327761 445.36501,151.407761 C441.23501,162.117761 425.15501,163.147761 415.71501,163.237761 C410.89501,163.237761 410.87501,170.787761 415.71501,170.738008 C427.22501,170.627761 441.24501,168.967761 449.12501,159.477761 C453.55501,154.137761 455.89501,145.627761 448.95501,141.317761 C445.13501,138.947761 440.42501,137.987761 436.14501,136.797761 C432.25501,135.727761 426.37501,134.797761 423.49501,131.677761 C420.27501,128.187761 424.55501,122.307761 427.49501,120.087761 C433.41501,115.607761 441.57501,114.817761 448.72501,114.727761 C453.54501,114.667761 453.56501,107.167761 448.72501,107.227403 L448.71501,107.247761 Z" id="路径" fill="url(#linearGradient-11)"></path>
        //             <path d="M497.94501,107.247761 C486.09501,107.387761 470.36501,110.247761 465.48501,122.777761 C461.32501,133.477761 468.48501,139.337761 477.86501,142.427761 C482.52501,143.957761 487.48501,144.757761 492.02501,146.627761 C495.02501,147.847761 495.78501,148.327761 494.59501,151.407761 C490.46501,162.117761 474.38501,163.147761 464.94501,163.237761 C460.12501,163.237761 460.10501,170.787761 464.94501,170.738008 C476.45501,170.627761 490.47501,168.967761 498.35501,159.477761 C502.78501,154.137761 505.12501,145.627761 498.18501,141.317761 C494.36501,138.947761 489.65501,137.987761 485.37501,136.797761 C481.48501,135.727761 475.60501,134.797761 472.72501,131.677761 C469.50501,128.187761 473.78501,122.307761 476.72501,120.087761 C482.64501,115.607761 490.80501,114.817761 497.95501,114.727761 C502.77501,114.667761 502.79501,107.167761 497.95501,107.227403 L497.94501,107.247761 Z" id="路径" fill="url(#linearGradient-12)"></path>
        //             <path d="M517.72501,151.747761 L532.83501,151.747761 C537.66501,151.747761 537.66501,144.247761 532.83501,144.247761 L517.72501,144.247761 C512.89501,144.247761 512.89501,151.747761 517.72501,151.747761 Z" id="路径" fill="url(#linearGradient-13)"></path>
        //             <path d="M517.78501,166.997761 C519.56501,154.227761 522.03501,141.417761 526.36501,129.237761 C527.735247,125.129643 529.542253,121.180379 531.75501,117.457761 C532.52501,116.217761 534.59501,112.877761 535.94501,112.757761 C539.82501,112.407761 543.30501,125.017761 544.12501,127.757761 C547.92501,140.467761 549.52501,153.757761 550.41501,166.977761 C550.73501,171.767761 558.23501,171.807761 557.91501,166.977761 C556.91501,151.977761 555.17501,136.527761 550.23501,122.267761 C548.13501,116.197761 544.31501,105.437761 536.38501,105.267761 C528.45501,105.097761 524.04501,115.267761 521.53501,121.107761 C515.53501,134.987761 512.62501,150.107761 510.53501,165.017761 C509.87501,169.737761 517.10501,171.777761 517.76501,167.017761 L517.78501,166.997761 Z" id="路径" fill="url(#linearGradient-14)"></path>
        //             <path d="M620.86501,56.9977606 C617.597749,93.5916433 617.597749,130.403878 620.86501,166.997761 C621.29501,171.767761 628.79501,171.807761 628.36501,166.997761 C625.097749,130.403878 625.097749,93.5916433 628.36501,56.9977606 C628.79501,52.1877606 621.29501,52.2177606 620.86501,56.9977606 L620.86501,56.9977606 Z" id="路径" fill="url(#linearGradient-15)"></path>
        //             <path d="M728.06501,107.247761 C716.21501,107.387761 700.48501,110.247761 695.60501,122.777761 C691.44501,133.477761 698.60501,139.337761 707.98501,142.427761 C712.64501,143.957761 717.60501,144.757761 722.14501,146.627761 C725.14501,147.847761 725.90501,148.327761 724.71501,151.407761 C720.58501,162.117761 704.50501,163.147761 695.06501,163.237761 C690.24501,163.237761 690.22501,170.787761 695.06501,170.738008 C706.57501,170.627761 720.59501,168.967761 728.47501,159.477761 C732.90501,154.137761 735.24501,145.627761 728.30501,141.317761 C724.48501,138.947761 719.77501,137.987761 715.49501,136.797761 C711.60501,135.727761 705.72501,134.797761 702.84501,131.677761 C699.62501,128.187761 703.90501,122.307761 706.84501,120.087761 C712.76501,115.607761 720.92501,114.817761 728.07501,114.727761 C732.89501,114.667761 732.91501,107.167761 728.07501,107.227403 L728.06501,107.247761 Z" id="路径" fill="url(#linearGradient-11)"></path>
        //             <path d="M79.6850098,0.0977606233 C77.4850098,-3.10223938 -9.03499025,73.0977606 0.775009752,105.187761 C8.15500975,129.297761 70.0750098,139.187761 70.1650098,137.247761 C70.2350098,135.607761 25.8550098,119.117761 21.1650098,99.8377606 C19.2450098,91.9077606 24.5450098,84.1777606 48.3750098,48.1777606 C69.4350098,16.3977606 79.9550098,0.487760623 79.6850098,0.0977606233 Z" id="路径" fill="url(#linearGradient-16)"></path>
        //             <path d="M35.3750098,219.157761 C37.5750098,222.357761 124.09501,146.157761 114.28501,114.067761 C106.90501,89.9577606 44.9850098,80.0677606 44.8950098,82.0077606 C44.8250098,83.6477606 89.2050098,100.137761 93.8950098,119.417761 C95.8150098,127.347761 90.5150098,135.077761 66.6850098,171.077761 C45.6250098,202.857761 35.0650098,218.757761 35.3750098,219.157761 Z" id="路径" fill="url(#linearGradient-17)"></path>
        //             <path d="M569.39501,114.327761 L606.89501,114.327761 C611.72501,114.327761 611.72501,106.827761 606.89501,106.827761 L569.39501,106.827761 C564.56501,106.827761 564.56501,114.327761 569.39501,114.327761 Z" id="路径" fill="url(#linearGradient-18)"></path>
        //             <path d="M583.14501,88.0777606 L583.14501,133.997761 C583.14501,139.997761 583.08501,145.927761 583.14501,151.897761 C583.27501,164.837761 594.14501,173.597761 606.89501,169.697761 C611.49501,168.287761 609.53501,161.047761 604.89501,162.467761 C597.26501,164.807761 590.78501,160.467761 590.62501,152.467761 C590.62501,151.307761 590.62501,150.127761 590.62501,148.967761 L590.62501,88.0777606 C590.62501,83.2477606 583.12501,83.2477606 583.12501,88.0777606 L583.14501,88.0777606 Z" id="路径" fill="url(#linearGradient-19)"></path>
        //             <path d="M643.39501,151.747761 L658.50501,151.747761 C663.33501,151.747761 663.33501,144.247761 658.50501,144.247761 L643.39501,144.247761 C638.56501,144.247761 638.56501,151.747761 643.39501,151.747761 Z" id="路径" fill="url(#linearGradient-20)"></path>
        //             <path d="M643.45501,166.997761 C645.23501,154.227761 647.70501,141.417761 652.03501,129.237761 C653.405247,125.129643 655.212253,121.180379 657.42501,117.457761 C658.19501,116.217761 660.26501,112.877761 661.61501,112.757761 C665.49501,112.407761 668.97501,125.017761 669.79501,127.757761 C673.59501,140.467761 675.19501,153.757761 676.08501,166.977761 C676.40501,171.767761 683.90501,171.807761 683.58501,166.977761 C682.58501,151.977761 680.84501,136.527761 675.90501,122.267761 C673.80501,116.197761 669.98501,105.437761 662.05501,105.267761 C654.12501,105.097761 649.71501,115.267761 647.20501,121.107761 C641.20501,134.987761 638.29501,150.107761 636.20501,165.017761 C635.54501,169.737761 642.77501,171.777761 643.43501,167.017761 L643.45501,166.997761 Z" id="路径" fill="url(#linearGradient-21)"></path>
        //         </g>
        //     </g>
        // </svg>
    )
}
