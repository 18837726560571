/* eslint-disable jsx-a11y/anchor-is-valid */
import { Image, ImageViewer, Toast } from "antd-mobile"
import { ScanningOutline } from "antd-mobile-icons"
import { useNavigate } from "react-router-dom"
import { LogoEnIcon } from "../../assets/icons/logo_en_icon"
import { copyHandle } from "../../utils/clipboard"
import { nativeApiDef, UseNativeApi } from "../../utils/native"

const nativeApi = UseNativeApi()

export const ClassImportTutorials = () => {

    const navigate = useNavigate()

    return (
        <>
            <div style={{
                "display": "flex",
                "padding": "30px 35px 0px 35px"
            }}>
                <div className="fitnessatlasIcon" onClick={() => { navigate("/") }}>
                    <LogoEnIcon />
                </div>
                <div style={{ "flex": "1" }}></div>
                <div className="HomeTitle">
                    <div style={{
                        "font-weight": "bolder",
                        "font-size": "1em",
                        "color": "#707070"
                    }}>智体数据中心</div>
                    <div style={{
                        "font-weight": "bolder",
                        "font-size": "1.4em"
                    }}>导入指引</div>
                </div>
            </div>

            <div className='HomeCardWithTitle'>
                <div className='CardTitleBar'>
                    <div className='CardIcon TextIcon'>
                        1.
                    </div>
                    <div className='CardTitle'>访问智体数据中心</div>
                    <div style={{ "flex": "1" }}></div>
                    <div className='CardIcon'>

                    </div>
                </div>
                <div style={{
                    "padding": "0 15px",
                    "display": "flex",
                    flexDirection: "column"
                }}>
                    <p><strong> 1. </strong>打开电脑浏览器，访问 <a onClick={() => {
                        nativeApi().share("https://t.fitnessatlas.cn")
                    }}><strong>t.fitnessatlas.cn</strong> (点击分享)</a></p>
                    <p>
                        <Image src="/img/ci_1.png" onClick={() => {
                            ImageViewer.show({
                                "image": "/img/ci_1.png"
                            })
                        }} />
                    </p>
                    <p><strong> 2. </strong>此时，您会看到二维码登录界面</p>
                    <p>
                        <Image src="/img/ci_2.png" onClick={() => {
                            ImageViewer.show({
                                "image": "/img/ci_2.png"
                            })
                        }} />
                    </p>
                </div>
            </div>

            <div className='HomeCardWithTitle'>
                <div className='CardTitleBar'>
                    <div className='CardIcon TextIcon'>
                        2.
                    </div>
                    <div className='CardTitle'>使用体测圈App扫码登录</div>
                    <div style={{ "flex": "1" }}></div>
                    <div className='CardIcon'>

                    </div>
                </div>
                <div style={{
                    "padding": "0 15px 5px 15px",
                    "display": "flex",
                    flexDirection: "column"
                }}>
                    <p><strong> 1. </strong>打开体测圈App，切换到团队页面</p>
                    <p>
                        <Image src="/img/ci_3.jpg" onClick={() => {
                            ImageViewer.show({
                                "image": "/img/ci_3.jpg"
                            })
                        }} />
                    </p>
                    <p><strong> 2. </strong>点击右上角扫描二维码按钮 <ScanningOutline /><a onClick={() => {
                        nativeApi().toScan();
                    }}><strong> (点此快速扫码)</strong></a></p>
                    <p><strong> 3. </strong>扫描电脑屏幕上的二维码</p>
                </div>

            </div>

            
            <div className='HomeCardWithTitle'>
                <div className='CardTitleBar'>
                    <div className='CardIcon TextIcon'>
                        3.
                    </div>
                    <div className='CardTitle'>导入班级</div>
                    <div style={{ "flex": "1" }}></div>
                    <div className='CardIcon'>

                    </div>
                </div>
                <div style={{
                    "padding": "0 15px 5px 15px",
                    "display": "flex",
                    flexDirection: "column"
                }}>
                    <p><strong> 1. </strong>进入班级导入</p>
                    <p>
                        <Image src="/img/ci_5.jpg" onClick={() => {
                            ImageViewer.show({
                                "image": "/img/ci_5.jpg"
                            })
                        }} />
                    </p>
                    <p><strong> 2. </strong>点击右上角<strong>下载模板</strong>按钮</p>
                    <p><strong> 3. </strong>打开下载好的<strong>班级导入模板</strong>，并按照屏幕上的规则进行填写</p>
                    <p>
                        <Image src="/img/ci_6.jpg" onClick={() => {
                            ImageViewer.show({
                                "image": "/img/ci_6.jpg"
                            })
                        }} /> 
                    </p>
                    <p><strong> 4. </strong>将填写完成的<strong>班级导入模板</strong>拖入<strong>上传区域</strong></p>
                    <p><strong> 5. </strong>如存在错误，请按照左侧的指引修正后重新导入</p>
                    <p>
                        <Image src="/img/ci_8.jpg" onClick={() => {
                            ImageViewer.show({
                                "image": "/img/ci_8.jpg"
                            })
                        }} />
                    </p>
                </div>

            </div>

            <div style={{"height":"20px"}}></div>
        </>
    )
}