// 工具类 主要封装与后端交互的
import axios from "axios";
import { Dialog, Toast } from "antd-mobile"
import md5 from "js-md5";
import { captcha } from "./captcha";
//let host = 'http://localhost:8000/api/v1'
let onlinehost = 'https://api.fitnessatlas.cn:8000/api/v1'
let testhost = 'https://api.test.fitnessatlas.cn:8000/api/v1'

function getHost(){
    if(localStorage.getItem("host")){
        return localStorage.getItem("host")
    }
    if(window.location.host.indexOf("localhost") >= 0){
        return testhost
    }
    return window.location.host.indexOf(".test.fitnessatlas.cn") >= 0 ? testhost : onlinehost
}

let host = getHost()

function login(info) {
   
    return axios({
        method: "post", 
        url: host + "/user/student/login",
        data: {
            "tel": info.tel,
            "password":  md5(info.password),
        },
        headers: {
            // "Content-Type":"multipart/form-data",
        }
    }).then(checkCodeWithToast, netWorkErr)
}
function checkCodeWithToast(resp) {
    console.log("checkCode")
    return new Promise((resolve, reject) => {
        if (resp.data.code == 1000) {
            Toast.show({
                icon: 'success'
            })
            resolve(resp.data.data)
        } else {
            let value = resp;
            if(value.data["code"] === 1007 || value.data["code"] === 1006 || value.data["code"] === 1026){
                window.location.replace("https://"+ window.location.host + "/login_view")
                return
            }
            console.log("checkCode.reject")
            Toast.show({
                icon: 'fail',
                content: resp.data.msg,
            })
            reject(resp.data.msg)
        } 
    });
}
function checkCode(resp) {
    console.log("checkCode")
    return new Promise((resolve, reject) => {
        if (resp.data.code == 1000) {
            resolve(resp.data.data)
        } else {
            let value = resp;
            if(value.data["code"] === 1007 || value.data["code"] === 1006 || value.data["code"] === 1026){
                window.location.replace("https://"+ window.location.host + "/login_view")
                return
            }
            reject(resp.data.msg)
        }
    });
}
function netWorkErr(err) {
    console.log(err)
    return new Promise((resolve, reject) => {
        Toast.show({
            icon: 'fail',
            content: "网络异常，请重新尝试",
        })
        reject("网络异常，请重新尝试")
    })

}
function getWithToken(url) {
    return axios({
        method: "get",
        url: host + url,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}
function WithTokenInstance() {
    return axios.create({
        baseURL: host,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    })
}
function studentInfo() {
    return axios({
        method: "get",
        url: host + "/user/student/info",
        headers: {
            "Authorization": "Bearer " + localStorage.getItem("token")
        }
    }).then(checkCode, netWorkErr)
}
export function submitExemptApplication(term,teacher_id,reason,urls){
    return WithTokenInstance().request({
        method: "post",
        url: "/test_free/record/create",
        data: {
            "term": term,
            "teacher_id":teacher_id,
            "reason":reason,
            "urls":urls
        }
    }).then(checkCode, netWorkErr)
}
export function getExemptApplications(){
    return WithTokenInstance().request({
        method: "post",
        url: "/test_free/record/find",
        data: {
            "page_num": 0,
            "page_size":100
        }
    }).then(checkCode, netWorkErr)
}
function schoolInfo(id) {
    return WithTokenInstance().request({
        method: "post",
        url: "/info/school",
        data: {
            "school_id": id,
        }
    }).then(checkCode, netWorkErr)
}

function gradeInfo(id) {
    return WithTokenInstance().request({
        method: "post",
        url: "/info/grade",
        data: {
            "grade_id": id,
        }
    }).then(checkCode, netWorkErr)
}
function classInfo(id) {
    return WithTokenInstance().request({
        method: "post",
        url: "/info/class",
        data: {
            "class_id": id,
        }
    }).then(checkCode, netWorkErr)
}
export function getTeamTeachers(school_id) {
    return WithTokenInstance().request({
        method: "post",
        url: "/test_free/team_teachers",
        data: {
            "school_id": school_id,
        }
    }).then(checkCode, netWorkErr)
}
function avatar(id) {
    return getWithToken("/face/cos/presign_get" + "?student_id=" + id).then(checkCode, netWorkErr)
}
function gradeList(id) {
    if (typeof id == "string") {
        id = parseInt(id)
    }
    return WithTokenInstance().request({
        method: "post",
        url: "/info/grade",
        data: {
            "school_id": id,
        }
    }).then(checkCode, netWorkErr)
}
const queryGradeList = (query) => {
    return WithTokenInstance().request({
        method: "post",
        url: "/info/grade",
        data: query
    }).then(checkCode, netWorkErr)
}
function classList(id) {
    if (typeof id == "string") {
        id = parseInt(id)
    }
    return WithTokenInstance().request({
        method: "post",
        url: "/info/class",
        data: {
            "grade_id": id,
        }
    }).then(checkCode, netWorkErr)
}

const queryClassList = (query) => {
    return WithTokenInstance().request({
        method: "post",
        url: "/info/class",
        data: query
    }).then(checkCode, netWorkErr)
}

function classListBySchoolId(id) {
    if (typeof id == "string") {
        id = parseInt(id)
    }
    return WithTokenInstance().request({
        method: "post",
        url: "/info/class",
        data: {
            "school_id": id,
        }
    }).then(checkCode, netWorkErr)
}
function inviteCodeGetSchoolInfo(code) {
    return WithTokenInstance().request({
        method: "post",
        url: "/org/info",
        data: {
            "student_invite_code": code,
        }
    }).then(checkCodeWithToast, netWorkErr)
}

function joinSchool(id) {
    return WithTokenInstance().request({
        method: "post",
        url: "/org/student/join_school",
        data: {
            "school_id": id,
        }
    }).then(checkCodeWithToast, netWorkErr)
}
function userInfoUpdate(data) {
    if (typeof data.nation == "string") {
        data.nation = name2Id(data.nation)
    }
    return WithTokenInstance().request({
        method: "post",
        url: "/info_update/user/student",
        data: data,
    }).then(checkCodeWithToast, netWorkErr)
}
const joinGradeAndClass = (grade_id, class_id) => {
    return WithTokenInstance().request({
        method: "post",
        url: "/org/student/join_grade_and_class",
        data: {
            "class_id": class_id,
            "grade_id": grade_id
        },
    }).then(checkCodeWithToast, netWorkErr)
}
const sendValidationCode = async (tel) => {
    let captchaObj =  await captcha();
    return await WithTokenInstance().request({
        method: "post",
        url: "/message/send",
        data:{
            "tel":tel,
            "captcha":captchaObj
        }
    }).then(checkCodeWithToast, netWorkErr)
}
function userRegister(data) {
    if (typeof data.nation == "string") {
        data.nation = name2Id(data.nation)
    }
    data.password = md5(data.password)
    return WithTokenInstance().request({
        method: "post",
        url: "/user/student/register",
        data: data,
    }).then(checkCodeWithToast, netWorkErr)
}
const userModifyPwd = (data)=>{
    data.password = md5(data.password)
    return WithTokenInstance().request({
        method: "post",
        url: "/user/student/password/change",
        data: data,
    }).then(checkCodeWithToast, netWorkErr)
}
export function presignPutGen(num) {
    return WithTokenInstance()
        .post("/cos/presign_put_gen",{num:num})
        .then(checkCode, netWorkErr)
}
export function getStudentExt(key) {
    return WithTokenInstance()
        .post("/student_ext/query",{
            key:key
        })
        .then(checkCode, netWorkErr)
}
export function setStudentExt(key,value) {
    return WithTokenInstance()
        .post("/student_ext/add_or_update",{
            key:key,
            value:value
        })
        .then(checkCode, netWorkErr)
}
export function getStudentTotalScore(student_id,term) {
    return WithTokenInstance()
        .post("/total_score/get/single_term",{
            student_id:student_id,
            term:term
        })
        .then(checkCode, netWorkErr)
}
export function confirmStudentTotalScore() {
    return WithTokenInstance()
        .post("/total_score/confirm")
        .then(checkCode, netWorkErr)
}
function presignURL() {
    return WithTokenInstance()
        .post("/face/cos/presign_put")
        .then(checkCode, netWorkErr)
}
function cosPutIMG(url, file) {
    return axios.request({
        method: "put",
        url: url,
        data: file,
        headers: { 'Content-Type': 'multipart/form-data;charset=utf-8' }
    })
}
function addFace(person_name, school_id) {

    return WithTokenInstance().request({
        method: "post",
        url: "/face/student/add_face",
        data: {
            "person_name": person_name,
            "school_id": school_id
        },
    }).then(checkCodeWithToast, netWorkErr)
}

const getSchoolTestInstance = ()=>{
    return WithTokenInstance().request({
        method: "get",
        url: "/test_conf/test_instant/offline",
    }).then(checkCodeWithToast, netWorkErr)
}
async function updateFace(file, person_name, school_id) {
    try {
        let presign_url = await presignURL()
        await cosPutIMG(presign_url.str, file)
        console.log(file);
        await sleep(1000)
        await addFace(person_name, school_id)
        Toast.show({
            icon: "success",
            content: "照片更新成功!"
        })
    } catch (err) {
        Toast.show({
            icon: "fail",
            content: "上传失败，请重新尝试"
        })
        throw err
    }
}

function invitedGetSchoolNoAuth(code) {
    return WithTokenInstance().request({
        method: "post",
        url: "/org/no_auth_info",
        data: {
            "student_invite_code": code,
        }
    }).then(checkCode, netWorkErr)
}
function getRecords(student_id, term) {
    return WithTokenInstance().request({
        method: "get",
        url: "/record/query_student",
        params: {
            "student_id": student_id,
            "term": term
        },
    }).then(checkCodeWithToast, netWorkErr)
}
async function getGradeClassName(grade_id, class_id) {
    if (grade_id == 0) {
        return ["请选择年级", "班级"]
    }
    let grade = await gradeInfo(grade_id);
    let xclass = await classInfo(class_id);
    // resolve()
    return [grade[0].name, xclass[0].name]
}

async function schoolOptions(id) {
    let [grades, clazz] = await Promise.all([gradeList(id), classListBySchoolId(id)])
    // console.log(grades,clazz)
    let children = []
    grades.forEach(g => {
        let grade = {
            "label": g.name,
            "value": g.id,
            "children": []
        }
        clazz.forEach(c => {
            if (c.grade_id == g.id) {
                grade.children.push({
                    "label": c.name,
                    "value": c.id,
                })
            }
        })
        children.push(grade)
    })
    return children
}
let req = {
    login,
    getWithToken,
    WithTokenInstance,
    studentInfo,
    schoolInfo,
    gradeInfo,
    classInfo,
    avatar,
    gradeList,
    classList,
    inviteCodeGetSchoolInfo,
    joinSchool,
    userInfoUpdate,
    userRegister,
    presignURL,
    cosPutIMG,
    addFace,
    updateFace,
    invitedGetSchoolNoAuth,
    getRecords,
    getGradeClassName,
    schoolOptions,
    queryGradeList,
    queryClassList,
    joinGradeAndClass,
    sendValidationCode,
    userModifyPwd,
    getSchoolTestInstance
}
// 民族代码处理
function code2Name(id) {
    for (let i = 0; i < nation_list.length; i++) {
        if (id == nation_list[i].id) {
            return nation_list[i].name
        }
    }
    return ""
}
function name2Id(str) {
    for (let i = 0; i < nation_list.length; i++) {
        if (str == nation_list[i].name) {
            return nation_list[i].id
        }
    }
    return ""
}
function getNatinonList() {
    return nation_list;
}
function nationList4Picker() {
    let ret = [[]]
    for (let i = 0; i < nation_list.length; i++) {
        ret[0].push(nation_list[i].name)
    }
    return ret
}
let nationUtils = {
    code2Name,
    name2Id,
    getNatinonList,
    nationList4Picker
}
const nation_list = [
    { id: 1, name: '汉族' },
    { id: 2, name: '蒙古族' },
    { id: 3, name: '回族' },
    { id: 4, name: '藏族' },
    { id: 5, name: '维吾尔族' },
    { id: 6, name: '苗族' },
    { id: 7, name: '彝族' },
    { id: 8, name: '壮族' },
    { id: 9, name: '布依族' },
    { id: 10, name: '朝鲜族' },
    { id: 11, name: '满族' },
    { id: 12, name: '侗族' },
    { id: 13, name: '瑶族' },
    { id: 14, name: '白族' },
    { id: 15, name: '土家族' },
    { id: 16, name: '哈尼族' },
    { id: 17, name: '哈萨克族' },
    { id: 18, name: '傣族' },
    { id: 19, name: '黎族' },
    { id: 20, name: '傈僳族' },
    { id: 21, name: '佤族' },
    { id: 22, name: '畲族' },
    { id: 23, name: '高山族' },
    { id: 24, name: '拉祜族' },
    { id: 25, name: '水族' },
    { id: 26, name: '东乡族' },
    { id: 27, name: '纳西族' },
    { id: 28, name: '景颇族' },
    { id: 29, name: '柯尔克孜族' },
    { id: 30, name: '土族' },
    { id: 31, name: '达翰尔族' },
    { id: 32, name: '么佬族' },
    { id: 33, name: '羌族' },
    { id: 34, name: '布朗族' },
    { id: 35, name: '撒拉族' },
    { id: 36, name: '毛南族' },
    { id: 37, name: '仡佬族' },
    { id: 38, name: '锡伯族' },
    { id: 39, name: '阿昌族' },
    { id: 40, name: '普米族' },
    { id: 41, name: '塔吉克族' },
    { id: 42, name: '怒族' },
    { id: 43, name: '乌孜别克族' },
    { id: 44, name: '俄罗斯族' },
    { id: 45, name: '鄂温克族' },
    { id: 46, name: '德昂族' },
    { id: 47, name: '保安族' },
    { id: 48, name: '裕固族' },
    { id: 49, name: '京族' },
    { id: 50, name: '塔塔尔族' },
    { id: 51, name: '独龙族' },
    { id: 52, name: '鄂伦春族' },
    { id: 53, name: '赫哲族' },
    { id: 54, name: '门巴族' },
    { id: 55, name: '珞巴族' },
    { id: 56, name: '基诺族' },
    { id: 57, name: '其他' },
    { id: 58, name: '外国血统' },]

// 常用工具方法    
function parseInvitedCode() {
    let url = window.location.href
    let params = new URLSearchParams(url.split("?")[1])
    return params.get("inviteCode")
}
function getTerms(cnt) {
    if(!cnt){cnt = 4}
    let now = new Date();
    let now_year = now.getFullYear()
    let now_month = now.getMonth() + 1;
    let result = []
    for(let i=0;i<cnt;i++){
        let currentYear = now_year - i;
        result.push(`${currentYear}-${currentYear + 1}-1`)
        result.push(`${currentYear - 1}-${currentYear}-2`)
    }
    if (now_month >= 9) {
        return { terms: result, term_idx: 0 }
    }
    return { terms: result, term_idx: 1 }
}
function termsList4Picker(termConf) {
    const { terms, term_idx } = termConf
    let ret = [[]]
    for (let i = 0; i < terms.length; i++) {
        ret[0].push(terms[i])
    }
    return ret
}
function classId2Name(grade_id, class_id, options) {
    let grade_name = ""
    let class_name = ""
    for (let i = 0; i < options.length; i++) {
        if (options[i].value == grade_id) {
            grade_name = options[i].label
            options[i].children.forEach(child => {
                if (child.value == class_id) {
                    class_name = child.label
                }
            });
            break
        }
    }
    return [grade_name, class_name]
}
function deepClone(obj) {
    console.log(obj)
    let s = JSON.stringify(obj)
    let result = JSON.parse(s)
    console.log(result)
    return result
}



let utils = {
    parseInvitedCode,
    getTerms,
    termsList4Picker,
    classId2Name,
    deepClone,
    sleep
}

function img_resize(file) {
    return new Promise((resolve, reject) => {
        var result = null;
        console.log(file)
        let reader = new FileReader()
        reader.onload = function (e) {
            console.log("reader.onload")
            let img = new Image()
            img.onload = function () {
                console.log("img.onload")
                let canvas = document.createElement("canvas")
                let context = canvas.getContext('2d')
                if (img.width > img.height) {
                    let rate = img.width / 1600;
                    canvas.width = 1600
                    canvas.height = img.height / rate
                } else {
                    let rate = img.height / 1600;
                    canvas.height = 1600
                    canvas.width = img.width / rate
                }
                context.drawImage(img, 0, 0, canvas.width, canvas.height)
                let data = canvas.toDataURL("image/jpeg")
                canvas.toBlob((blob) => {
                    result = blob
                    console.log(result)
                    result = new window.File([result], "filename")
                    console.log(result)
                    resolve(result)
                }, "image/jpeg")
            }
            img.src = e.target.result
        }
        reader.readAsDataURL(file)
    })
}
function sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
}

export {
    req, nationUtils, utils,
    img_resize
}