export const nativeApiDef = {
    toScan:()=>{},
    share:(value)=>{}
}

export const UseNativeApi = ()=>{
    let def = ()=>nativeApiDef
    // eslint-disable-next-line no-undef
    def = ()=>native
    return def
}